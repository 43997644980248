<script setup lang="ts">
import { getLogger } from '@/composables/util/log'
import { useNotificationStore } from '@/stores/notification'

import { organizationService } from '@/capability/organization/OrganizationService'
import type { PromptModel } from '@/capability/prompt/PromptModel'
import { promptService } from '@/capability/prompt/PromptService'
import { PromptDtoDocumentAgentTypeEnum, PromptDtoLineOfBusinessEnum } from 'typescript-core-api-client/dist/api'

import PromptInput from '@/component/prompt/PromptInput.vue'

const props = defineProps({
  dealId: {
    type: String,
    required: true
  },
  promptText: {
    type: String,
    default: ''
  },
  agentType: {
    type: String,
    default: PromptDtoDocumentAgentTypeEnum.Qa
  }
})

const emit = defineEmits<{
  (e: 'update:create', payload: PromptModel): void
}>()

const notificationStore = useNotificationStore()
const logger = getLogger('PromptCreate.vue')

const organizations = await organizationService.getAll()

const EMPTY_PROMPT: PromptModel = {
  name: '',
  documentType: '',
  extractionType: '',
  lineOfBusiness: PromptDtoLineOfBusinessEnum.Unknown,
  organizationId: '',
  order: 0,
  prompt: '',
  description: '',
  documentAgentType: props.agentType
} as PromptModel

async function handleSubmit(prompt: PromptModel) {
  try {
    const newPrompt = await promptService.createPrompt({ prompt })
    notificationStore.publishSuccessMessage('Prompt created successfully')
    emit('update:create', newPrompt)
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  }
}
</script>

<template>
  <PromptInput mode="create" :organizations="organizations" :prompt="EMPTY_PROMPT" @submit="handleSubmit" />
</template>
