import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import RqTooltipBtn from '@/component/arqu-components/shadcn/extensions/TooltipButton/TooltipButton.vue'
import RqBtn from '@/component/arqu-components/shadcn/ui/button/Button.vue'

export function registerGlobalComponents(app: App<Element>): void {
  app.component(
    'RqLoadingOverlay',
    defineAsyncComponent(() => import('@/component/arqu-components/RQLoadingOverlay.vue'))
  )
  app.component(
    'RqLoadingOverlayLinear',
    defineAsyncComponent(() => import('@/component/arqu-components/RQLoadingOverlayLinear.vue'))
  )
  app.component('RqBtn', RqBtn)
  app.component('RqTooltipBtn', RqTooltipBtn)
  app.component(
    'RqDataTable',
    defineAsyncComponent(() => import('@/component/arqu-components/tables/RQDataTable.vue'))
  )
  app.component(
    'RqDateInputComponent',
    defineAsyncComponent(() => import('@/component/arqu-components/RQDateInputComponent.vue'))
  )
  app.component(
    'RqAccordion',
    defineAsyncComponent(() => import('@/component/arqu-components/RQAccordion.vue'))
  )
  app.component(
    'RqAccordionItem',
    defineAsyncComponent(() => import('@/component/arqu-components/RQAccordionItem.vue'))
  )
  app.component(
    'RqInput',
    defineAsyncComponent(() => import('@/component/arqu-components/RQInput.vue'))
  )
  app.component(
    'RqInputNumber',
    defineAsyncComponent(() => import('@/component/arqu-components/RQInputNumber.vue'))
  )
  app.component(
    'RqInputCurrency',
    defineAsyncComponent(() => import('@/component/arqu-components/RQInputCurrency.vue'))
  )
  app.component(
    'RqRow',
    defineAsyncComponent(() => import('@/component/arqu-components/flex-grid/RQRow'))
  )
  app.component(
    'RqCol',
    defineAsyncComponent(() => import('@/component/arqu-components/flex-grid/RQCol'))
  )
  app.component(
    'RqTooltip',
    defineAsyncComponent(() => import('@/component/arqu-components/tooltip/RQTooltip.vue'))
  )
  app.component(
    'RqCard',
    defineAsyncComponent(() => import('@/component/arqu-components/card/RQCard.vue'))
  )
  app.component(
    'RqCardTitle',
    defineAsyncComponent(() => import('@/component/arqu-components/card/RQCardTitle.vue'))
  )
  app.component(
    'RqCardText',
    defineAsyncComponent(() => import('@/component/arqu-components/card/RQCardText.vue'))
  )
  app.component(
    'RqCardActions',
    defineAsyncComponent(() => import('@/component/arqu-components/card/RQCardActions.vue'))
  )
  app.component(
    'RqListboxSingle',
    defineAsyncComponent(() => import('@/component/arqu-components/shadcn/extensions/ListboxSingle.vue'))
  )
  app.component(
    'RqListboxMulti',
    defineAsyncComponent(() => import('@/component/arqu-components/shadcn/extensions/ListboxMulti.vue'))
  )
  app.component(
    'RqComboboxSingle',
    defineAsyncComponent(() => import('@/component/arqu-components/shadcn/extensions/ComboboxSingle.vue'))
  )
  app.component(
    'RqComboboxMulti',
    defineAsyncComponent(() => import('@/component/arqu-components/shadcn/extensions/ComboboxMulti.vue'))
  )
  app.component(
    'RqTextField',
    defineAsyncComponent(() => import('@/component/arqu-components/inputs/RQTextField.vue'))
  )
  app.component(
    'RqFileField',
    defineAsyncComponent(() => import('@/component/arqu-components/inputs/RQFileField.vue'))
  )
  app.component(
    'RqIcon',
    defineAsyncComponent(() => import('@/component/arqu-components/icon/RQIcon.vue'))
  )
  app.component(
    'RqTextarea',
    defineAsyncComponent(() => import('@/component/arqu-components/inputs/RQTextarea.vue'))
  )
  app.component(
    'RqSwitch',
    defineAsyncComponent(() => import('@/component/arqu-components/inputs/RQSwitch.vue'))
  )
  app.component(
    'RqCheckbox',
    defineAsyncComponent(() => import('@/component/arqu-components/shadcn/ui/checkbox/Checkbox.vue'))
  )
  app.component(
    'RqProgressCircular',
    defineAsyncComponent(() => import('@/component/arqu-components/progress/RQProgressCircular.vue'))
  )
  app.component(
    'RqProgressLinear',
    defineAsyncComponent(() => import('@/component/arqu-components/progress/RQProgressLinear.vue'))
  )
  app.component(
    'RqAvatar',
    defineAsyncComponent(() => import('@/component/arqu-components/avatar/RQAvatar.vue'))
  )
  app.component(
    'RqAlert',
    defineAsyncComponent(() => import('@/component/arqu-components/alert/RQAlert.vue'))
  )
}
