import type { Ensure } from '@/lib/types'

import type { DealMinimalModel } from '@/capability/deal/types/deal-minimal-model'
import type { DealModel } from '@/capability/deal/types/deal-model'
import type { DealDtoLikelihoodEnum, DealDtoStatusEnum } from 'typescript-core-api-client'
import { type MemoDto } from 'typescript-core-api-client'
import type { OrganizationDtoTypeEnum } from 'typescript-core-api-client/dist/api'

export type DealPagination = {
  pageSize: number
  pageNumber: number
  totalCount?: number
}

export type DealSorter = {
  field: string
  order: 'ASC' | 'DESC'
}

export enum SortDir {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type DealStatus = DealDtoStatusEnum

/**
 * The data model for the "Shared With" section of the Deal Contacts page.
 */
export class DealTeamUserViewModel {
  id: string = ''
  name: string = ''
  email: string = ''
  orgId: string = ''
  orgName: string = ''
  orgType: string = ''
  dealPermissions: string[] = []
  alertsOn: boolean = false
  selected: boolean = false
}

export type DealTeamViewModel = {
  name: string
  users: DealTeamUserViewModel[]
  orgType: OrganizationDtoTypeEnum
  selectableSubRoles: string[]
  titleClass: string
}

export const carrierSubRoles = ['', 'Manager', 'Underwriter'] // constants
export const arquSubRoles = ['', 'Risk Specialist', 'Program Manager']
export const retailerSubRoles = ['', 'Manager', 'Producer']

export type DealProductType = {
  value?: string
  label: string
}

export type DealIndustryType = {
  value?: string
  label: string
}

export type Progress = {
  name?: string
  value: string
}

export type Status = {
  name: string
  value: string
  roles: string[]
}

export type DealSortKeys =
  | 'policyInfo.effectiveStartDate'
  | 'policyInfo.effectiveEndDate'
  | 'clientInfo.name'
  | 'dealProgress'
  | 'displayName'
  | 'insuredInfo.name'
  | 'labelName'
  | 'name'
  | 'policyTerm'
  | 'practice'
  | 'projectInfo.name'
  | 'retailerInfo.name'
  | 'status'

export type DealSortType = {
  key: DealSortKeys
  order: 'asc' | 'desc'
}

export type FileInfoDto = {
  fullPath: string
  file: File
}

export type DealModelWithMemo = DealModel & { memo?: MemoDto & { carrier?: string } }

export type DealMinimalModelWithMemo = DealMinimalModel & { memo?: MemoDto & { carrier?: string } }

// Base DateGroup enum and types
export const DealDateGroupEnum = {
  'upcoming-thirty': 'Upcoming 30 Days',
  'next-thirty-sixty': 'Next 30-60 Days',
  'beyond-sixty': 'Beyond 60 Days',
  'past-due': 'Past Due'
} as const

export type DealDateGroupEnumType = typeof DealDateGroupEnum
export type DealDateGroupValueType = keyof DealDateGroupEnumType
export type DealDateGroupDisplayType = DealDateGroupEnumType[DealDateGroupValueType]

// Recently Added as its own category
export const RecentlyAddedEnum = {
  'recently-added': 'Recently Added'
} as const

export type RecentlyAddedEnumType = typeof RecentlyAddedEnum
export type RecentlyAddedValueType = keyof RecentlyAddedEnumType
export type RecentlyAddedDisplayType = RecentlyAddedEnumType[RecentlyAddedValueType]

// SwimLane types including DateGroup, RecentlyAdded, and additional swim lane-specific options
export const DealSwimLaneFilterEnum = {
  ...DealDateGroupEnum,
  ...RecentlyAddedEnum,
  'on-hold': 'On Hold',
  'bind-confirmed': 'Bind Confirmed'
} as const

export type DealSwimLaneFilterEnumType = typeof DealSwimLaneFilterEnum
export type DealSwimLaneValueType = keyof DealSwimLaneFilterEnumType
export type DealSwimLaneDisplayType = DealSwimLaneFilterEnumType[DealSwimLaneValueType]

// Types for filtering and display
export type DealDateGroupFilterType = {
  value: DealDateGroupValueType
  display: DealDateGroupDisplayType
}

export type RecentlyAddedFilterType = {
  value: RecentlyAddedValueType
  display: RecentlyAddedDisplayType
}

export type DealSwimLaneFilterType = {
  value: DealSwimLaneValueType
  display: DealSwimLaneDisplayType
}

// Type assertion to ensure DealSwimLaneFilter includes all fields from DealDateGroup and RecentlyAdded
type EnsureAllFieldsIncluded = Omit<typeof DealSwimLaneFilterEnum, keyof typeof DealDateGroupEnum | keyof typeof RecentlyAddedEnum> &
  typeof DealDateGroupEnum &
  typeof RecentlyAddedEnum

type _CheckSwimLaneInclusion = Ensure<typeof DealSwimLaneFilterEnum, EnsureAllFieldsIncluded>

export type DealGroupType = {
  value: DealSwimLaneValueType
  display: DealSwimLaneDisplayType
  deals: MappedDealModel[]
}

export type MappedDealModel = DealModelWithMemo & {
  client?: string
  effectiveDate?: string
  dueDate?: string
  retailer?: string
  modifiedAt?: number
  dealProgressPercentage?: number
  icon: string
  iconColor: string
  dealProgressOrder?: number
}

export type MappedDealMinimalModel = DealMinimalModelWithMemo & {
  client?: string
  effectiveDate?: number
  dueDate?: string
  retailer?: string
  modifiedAt?: number
  dealProgressPercentage?: number
  icon: string
  iconColor: string
  dealProgressOrder?: number
}

export type ExtendedDealTeamUserViewModel = DealTeamUserViewModel & {
  orgType: OrganizationDtoTypeEnum
}

export const surplusLinesTransactionTypes = [
  { value: 'NewPolicy', label: 'New Policy' },
  { value: 'RenewalPolicy', label: 'Renewal Policy' },
  { value: 'AdditionalPremiumEndorsement', label: 'Additional Premium Endorsement' },
  { value: 'AuditEndorsement', label: 'Audit Endorsement' },
  { value: 'AuditReturnPremiumEndorsement', label: 'Audit Return Premium Endorsement' },
  { value: 'Binder', label: 'Binder' },
  { value: 'ExtensionEndorsement', label: 'Extension Endorsement' },
  { value: 'FlatCancellation', label: 'Flat Cancellation' },
  { value: 'ProRataCancellation', label: 'Pro Rata Cancellation' },
  { value: 'Reinstatement', label: 'Reinstatement' },
  { value: 'ReturnPremiumEndorsement', label: 'Return Premium Endorsement' },
  { value: 'ZeroPremiumEndorsement', label: 'Zero Premium Endorsement' }
]

export type DealLikelihoodType = keyof typeof DealDtoLikelihoodEnum

export * from './deal-navigation'
export * from './deal-model'
export * from './deal-model-construction-attr'
