import { apiConfiguration } from '@/capability/system/Config'
import type { UserModel } from '@/capability/user/model'
import { UserApi } from 'typescript-core-api-client'
import type { ListUserTypeEnum } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type GetByIdPayloadType = {
  userId: string
}

export type GetAllByUserTypePayloadType = {
  userType: ListUserTypeEnum
}

export type UpdateUserPayloadType = {
  userId: string
  user: UserModel
}

export type ListPrivilegesPayloadType = {
  userId: string
  dealId: string
}

export interface UserService {
  getById: (payload: GetByIdPayloadType) => Promise<UserModel>
  getAll: () => Promise<UserModel[]>
  getAllByUserType: (payload: GetAllByUserTypePayloadType) => Promise<UserModel[]>
  getActive: () => Promise<UserModel[]>
  activate: (payload: GetByIdPayloadType) => Promise<UserModel>
  disable: (payload: GetByIdPayloadType) => Promise<UserModel>
  update: (payload: UpdateUserPayloadType) => Promise<UserModel>
  listPrivileges: (payload: ListPrivilegesPayloadType) => Promise<string[]>
}

export const userService = {
  getById: async function ({ userId }: GetByIdPayloadType): Promise<UserModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.getUserById(userId)
    return (response.data ?? {}) as UserModel
  },
  getAll: async function (): Promise<UserModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.list()
    return (response.data?.users ?? []) as UserModel[]
  },
  getAllByUserType: async function ({ userType }: GetAllByUserTypePayloadType): Promise<UserModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.list(userType)
    return (response.data?.users ?? []) as UserModel[]
  },
  getActive: async function (): Promise<UserModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.listAllActive()
    return (response.data?.users ?? []) as UserModel[]
  },
  activate: async function ({ userId }: GetByIdPayloadType): Promise<UserModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.activate(userId)
    return (response.data ?? {}) as UserModel
  },
  disable: async function ({ userId }: GetByIdPayloadType): Promise<UserModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.disable(userId)
    return (response.data ?? {}) as UserModel
  },
  update: async function (payload: UpdateUserPayloadType): Promise<UserModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const { userId, user } = payload
    const response = await userApi.update(userId, { user })
    return (response.data.user ?? {}) as UserModel
  },
  listPrivileges: async function ({ userId, dealId }: ListPrivilegesPayloadType): Promise<string[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.listPrivileges(userId, dealId)
    return response.data.privileges || []
  }
} as UserService
