import type { OrganizationIconModel, OrganizationModel } from '@/capability/organization/model'
import { apiConfiguration } from '@/capability/system/Config'
import type { UserModel } from '@/capability/user/model'
import { type GetOrganizationPrivilegesResponse, OrganizationApi } from 'typescript-core-api-client'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type GetByIdPayloadType = {
  organizationId: string
}

export type GetAllByTypePayloadType = {
  type: string
}

export type GetUsersByOrganizationPayloadType = {
  organizationId: string
}

export type GetUsersByOrganizationTypePayloadType = {
  organizationType: string
}

export type GetPrivilegesByOrganizationPayloadType = {
  organizationId: string
}

export type UploadOrgFilesPayloadType = {
  organizationId: string
  files: File[]
  paths: string[]
}

export interface OrganizationService {
  getById: (payload: GetByIdPayloadType) => Promise<OrganizationModel>
  getAll: () => Promise<OrganizationModel[]>
  getOrganizationsWithIcons: () => Promise<OrganizationIconModel[]>
  getAllByType: (payload: GetAllByTypePayloadType) => Promise<Array<OrganizationModel>>
  create: (organization: OrganizationModel) => Promise<OrganizationModel>
  update: (organization: OrganizationModel) => Promise<OrganizationModel>
  getOrganizationByCurrentUser: () => Promise<OrganizationModel>
  getUsersByOrganization: (payload: GetUsersByOrganizationPayloadType) => Promise<UserModel[]>
  getAllUsersByOrganization: ({ organizationId }: GetUsersByOrganizationPayloadType) => Promise<UserModel[]>
  getUsersByOrganizationType: (payload: GetUsersByOrganizationTypePayloadType) => Promise<UserModel[]>
  getPrivilegesByOrganization: (payload: GetPrivilegesByOrganizationPayloadType) => Promise<GetOrganizationPrivilegesResponse>
  uploadOrganizationIcon: (payload: UploadOrgFilesPayloadType) => Promise<string>
  disable: (payload: GetByIdPayloadType) => Promise<OrganizationModel>
  enable: (payload: GetByIdPayloadType) => Promise<OrganizationModel>
}

export const organizationService = {
  getAllByType: async function ({ type }: GetAllByTypePayloadType): Promise<Array<OrganizationModel>> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.getAllOrganizationsByType(type)
    return response.data.organizations || []
  },

  create: async function (organization: OrganizationModel): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.create({ organization })
    return response.data.organization || organization
  },

  update: async function (organization: OrganizationModel): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.update({ organization: organization })
    return response.data.organization || organization
  },

  getAll: async function (): Promise<OrganizationModel[]> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.getAllOrganizations()
    return response.data.organizations ?? []
  },

  getOrganizationsWithIcons: async function (): Promise<OrganizationIconModel[]> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.getOrganizationsWithIcons()
    return response.data.organizations || []
  },

  getPrivilegesByOrganization: async function ({
    organizationId
  }: GetPrivilegesByOrganizationPayloadType): Promise<GetOrganizationPrivilegesResponse> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.getPrivilegesByOrganizationId(organizationId)
    return response.data
  },

  getOrganizationByCurrentUser: async function (): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)

    const response = await organizationApi.getOrganizationByUser()
    return response.data.organization!!
  },

  getUsersByOrganization: async function ({ organizationId }: GetUsersByOrganizationPayloadType): Promise<UserModel[]> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.getUsersByOrganizationId(organizationId)
    return response.data.users ?? []
  },

  getAllUsersByOrganization: async function ({ organizationId }: GetUsersByOrganizationPayloadType): Promise<UserModel[]> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.getAllUsersByOrganizationId(organizationId)
    return response.data.users ?? []
  },

  getUsersByOrganizationType: async function ({ organizationType }: GetUsersByOrganizationTypePayloadType): Promise<UserModel[]> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.getUsersByOrganizationType(organizationType)
    return response.data.users ?? []
  },
  getById: async function ({ organizationId }: GetByIdPayloadType): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.getAccountById(organizationId)
    return response.data.organization ?? ({} as OrganizationModel)
  },
  disable: async function ({ organizationId }: GetByIdPayloadType): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.disable(organizationId)
    return response.data.organization ?? ({} as OrganizationModel)
  },
  enable: async function ({ organizationId }: GetByIdPayloadType): Promise<OrganizationModel> {
    const config = await apiConfiguration()
    const organizationApi = new OrganizationApi(config)
    const response = await organizationApi.enable(organizationId)
    return response.data.organization ?? ({} as OrganizationModel)
  },
  uploadOrganizationIcon: async function (payload: UploadOrgFilesPayloadType): Promise<string> {
    const { organizationId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    for (const path of paths) {
      formData.append('filePaths', path)
    }

    const response = await fetch(`${config.basePath}/v1/organizations/${organizationId}/files/icon`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: formData
    })

    if (!response.ok) {
      throw new Error('Failed to upload organization icon')
    }

    const data: { iconUrl: string } = await response.json()
    return data.iconUrl ?? ''
  }
} as OrganizationService
