<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, inject } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { DialogConfirm } from '@/composables/dialog/dialogConfirm'

import type { LayerModel } from '@/capability/layer/LayerModel'

import LayerModifyStructure from '@/component/layer/LayerModifyStructure.vue'

const props = defineProps({
  segment: {
    type: Object as PropType<LayerModel>,
    required: true
  },
  isPlugSegment: {
    type: Boolean,
    required: false,
    default: false
  },
  layerExcess: {
    type: Number,
    required: false,
    default: 0
  },
  layerLimit: {
    type: Number,
    required: false,
    default: 0
  },
  names: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  errors: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  }
})

const emit = defineEmits<{
  (e: 'update:segment', payload: LayerModel): void
  (e: 'removeSegment', payload: String): void
}>()

const layers = computed({
  get(): LayerModel[] {
    return props.segment!.layers ?? ([] as LayerModel[])
  },
  set(newValue: LayerModel[]): void {
    const segment = cloneDeep(props.segment)
    segment.layers = newValue
    emit('update:segment', segment)
  }
})

const percent = computed({
  get(): string {
    return props.segment!.percentage!.toString()
  },
  set(newValue: string) {
    if (!props.isPlugSegment) {
      const segment = cloneDeep(props.segment)
      segment.percentage = +newValue
      emit('update:segment', segment)
    }
  }
})

function updateName(event: FocusEvent): void {
  name.value = (event.target as HTMLInputElement).value
}

const name = computed({
  get(): string {
    return props.segment!.name ?? props.segment!.getNameWithDefault()!
  },
  set(newValue: string) {
    const segment = cloneDeep(props.segment)
    segment.name = newValue
    emit('update:segment', segment)
  }
})

const layerMax = computed(() => (props.layerExcess ?? 0) + (props.layerLimit ?? 0))

const confirm = inject(DialogConfirm)!

async function handleRemove() {
  if (!props.isPlugSegment) {
    await confirm({
      icon: 'mdi-trash-can-outline',
      title: 'Delete Layer',
      description: `Are you sure you want to delete "${name.value}"?`,
      okText: 'Yes, delete it',
      cancelText: "No, don't delete it",
      onOk: async () => {
        await emit('removeSegment', props.segment!.id as string)
      }
    })
  }
}
</script>

<template>
  <div class="grid grid-cols-11 items-end gap-1">
    <div v-if="isPlugSegment" class="col-span-11 text-sm">
      <span v-if="isPlugSegment" class="text-caption">
        The total is not 100%. If you hit "apply" then the segment below will be automatically added to fill in the remaining space.
      </span>
    </div>
    <div class="col-span-3">
      <rq-text-field
        v-model="percent"
        :id="`${segment.id}-input`"
        append-icon="mdi:percent"
        datacy="segmentPercentInput"
        details-size="x-small"
        :errors="errors"
        :persistent-hint="isPlugSegment"
        :readonly="isPlugSegment"
        type="number"
      >
        <template #label>
          <span class="text-xs">Percent</span>
        </template>
      </rq-text-field>
    </div>
    <div class="col-span-6">
      <rq-text-field :id="`${segment.id}-name`" :model-value="name" type="text" @blur="updateName">
        <template #label>
          <span class="text-xs">Segment Name</span>
        </template>
      </rq-text-field>
    </div>
    <div v-if="!isPlugSegment" class="col-span-2 flex items-center justify-end pl-4">
      <LayerModifyStructure
        v-model:layers="layers"
        :in-schematic-diagram="false"
        :names="names"
        :tower-id="segment.id as string"
        :tower-max="layerMax"
        :tower-min="layerExcess"
        :tower-name="segment.name"
      />
      <rq-btn icon="square" size="lg" type="button" variant="ghost" @click="handleRemove">
        <rq-icon icon="lucide:circle-minus" />
      </rq-btn>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
