import { reactive, ref } from 'vue'
import { defineStore } from 'pinia'

import { getLogger } from '@/composables/util/log'

import type { ToastProps } from '@/component/arqu-components/shadcn/ui/toast'
import { useToast } from '@/component/arqu-components/shadcn/ui/toast/use-toast'

export type PublishByTypePayloadType = {
  message: string
  type: 'error' | 'info' | 'warning' | 'success'
  options?: ToastProps
}

export type PublishWithLoggingOptions = {
  options?: ToastProps
  enableLogging?: boolean
}

export const useNotificationStore = defineStore('notification', () => {
  const { toast } = useToast()

  const notificationSignal = ref<string>('')
  const notificationMeta = reactive({})

  function publishSuccessMessage(message: string, options?: ToastProps) {
    if (options?.id && !options?.timeout) {
      options.timeout = 15000
    }
    toast({ description: message, variant: 'success', ...options })
  }

  function publishInfoMessage(message: string, options?: ToastProps) {
    if (options?.id && !options?.timeout) {
      options.timeout = 15000
    }
    toast({ description: message, variant: 'info', ...options })
  }

  function publishWarningMessage(message: string, options?: ToastProps) {
    if (options?.id && !options?.timeout) {
      options.timeout = 15000
    }
    toast({ description: message, variant: 'warning', ...options })
  }

  function publishErrorMessage(message: string, options?: ToastProps) {
    if (options?.id && !options?.timeout) {
      options.timeout = 15000
    }
    toast({ description: message, variant: 'error', ...options })
  }

  function publishOneOrMoreErrUnhandled(oneOrMoreErr: Error, _options?: PublishWithLoggingOptions) {
    const { options, enableLogging } = _options || {}
    let message: string

    if ((oneOrMoreErr as any)?.response?.data?.code == 'validation_error') {
      message = (oneOrMoreErr as any)?.response?.data?.fieldErrors
        ?.map((fieldError: any) => `${fieldError.field} ${fieldError.message}`)
        .join('\n')
    } else {
      message = (oneOrMoreErr as any)?.response?.data?.message || oneOrMoreErr || 'Unknown Error'
    }

    if (options?.id && !options?.timeout) {
      options.timeout = 15000
    }

    if (enableLogging !== false) {
      // Get logger based on where error occurred rather than notification store
      const logger = getLogger(oneOrMoreErr.stack?.split('\n')[2]?.split('/').pop()?.split(':')[0] || 'unknown')
      logger.error(oneOrMoreErr)
    }

    toast({ description: message, variant: 'error', ...options })
  }

  function publishByType(payload: PublishByTypePayloadType) {
    const { message, type, options } = payload
    switch (type) {
      case 'success':
        toast({ description: message, variant: 'success', ...options })
        break
      case 'error':
        toast({ description: message, variant: 'error', ...options })
        break
      case 'info':
        toast({ description: message, variant: 'info', ...options })
        break
      case 'warning':
        toast({ description: message, variant: 'warning', ...options })
        break
    }
  }

  return {
    publishSuccessMessage,
    publishInfoMessage,
    publishWarningMessage,
    publishErrorMessage,
    publishOneOrMoreErrUnhandled,
    publishByType,
    notificationSignal,
    notificationMeta
  }
})

export type NotificationStoreType = typeof useNotificationStore
export type NotificationStoreReturnType = ReturnType<NotificationStoreType>
