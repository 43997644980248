<script lang="ts">
import type { DealModel } from '@/capability/deal/types'

export interface Props {
  organizationId: string
  programName: string
  deal: DealModel
}
</script>

<script setup lang="ts">
import { onMounted } from 'vue'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { z } from 'zod'

import {
  type HandleEditTemplateType,
  type HandleUpdatePreviewType,
  useClearingSubmission
} from '@/composables/components/clearing-submission'
import type { PageMetaType } from '@/lib/types/router'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import { clearingService } from '@/capability/clearing/ClearingService'
import type { ClearingModel } from '@/capability/clearing/model'
import type { GetDraftType } from '@/capability/clearing/types'
import { getDraft } from '@/capability/clearing/utils'
import { dealService } from '@/capability/deal/services'
import { getProvisionedUsers } from '@/capability/deal/utils/provisioning'
import { documentNotShareable } from '@/capability/document/util'
import { emailService } from '@/capability/email/EmailService'
import type { EmailTemplateConfigImpl } from '@/capability/email/types'
import type { OrganizationModel } from '@/capability/organization/model'
import { organizationService } from '@/capability/organization/OrganizationService'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { ProgramView } from '@/capability/program/ProgramModel'
import { userService } from '@/capability/user/UserService'
import type { MappedUserType } from '@/capability/user-group/types'
import { mapUser } from '@/capability/user-group/utils'
import type { DocumentDto, RenderTemplateResponse } from 'typescript-core-api-client'
import type { CreateClearingResponse } from 'typescript-core-api-client/dist/api'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ClearingSubmissionDraftEditor from '@/component/clearing-submission/clearing-submission-draft/ClearingSubmissionDraftEditor.vue'
import ClearingSubmissionDraftPreview from '@/component/clearing-submission/clearing-submission-draft/ClearingSubmissionDraftPreview.vue'
import PublishToRetailerConfirmationDialog from '@/component/program/program-navbar/publish-to-retailer/PublishToRetailerConfirmationDialog.vue'

const props = defineProps<Props>()

const route = useRoute()
const authStore = useAuthStore()
const programStore = useProgramStore()
const notificationStore = useNotificationStore()

const dealId = route.params.dealId as string
const programId = route.params.programId as string

const submitDialog = ref<boolean>(false)
const selectedUsers = ref<MappedUserType[]>([])
const publishButtonColor = ref<string>('pink-btn')
const draftDisabled = computed(() => !!errors.value.list.length)

const templates = ref<EmailTemplateConfigImpl[]>([])
const org = ref<OrganizationModel>()
const documents = ref<DocumentDto[]>([])
const availableUsers = ref<MappedUserType[]>([])

onMounted(async () => {
  const getEmailTemplates = emailService
    .getEmailTemplates()
    .then((res) => {
      templates.value = res
    })
    .catch((err) => {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    })
  const getById = organizationService
    .getById({ organizationId: props.organizationId as string })
    .then((organization) => {
      org.value = organization
      if (organization) {
        userService.getAll().then((users) => {
          availableUsers.value = users.map(mapUser([org.value!])).filter((u) => {
            if (Object.keys(org.value!).length > 0) {
              let namesMatch = u.organization?.name?.toLowerCase() === org.value!.name?.toLowerCase()
              if (org.value!.domain) {
                namesMatch = namesMatch || !!u.email?.toLowerCase()?.includes(org.value!.domain?.toLowerCase())
              }
              return namesMatch
            }
            return false
          })
        })
      }
    })
    .catch((err) => {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    })
  const getDocuments = dealService
    .getDocuments({ dealId })
    .then((res) => {
      documents.value = res?.documents ?? []
    })
    .catch((err) => {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    })

  await Promise.all([getEmailTemplates, getById, getDocuments])
})

function getTemplate(): string {
  const meta: PageMetaType = route.meta
  let viewName = ProgramView.UNKNOWN
  if (meta.viewName) {
    viewName = ProgramView[meta.viewName as keyof typeof ProgramView]
  }
  switch (viewName) {
    case ProgramView.STRUCTURE:
      // deal.status = DealDtoStatusEnum.Active
      // deal.dealProgress = DealDtoDealProgressEnum.Active
      return 'SpecsAvailable'
    case ProgramView.INDICATION_REQUEST:
      return 'MarketingActivityAvailable'
    case ProgramView.REQUEST_FORMAL_QUOTE:
      // deal.dealProgress = DealDtoDealProgressEnum.BindRequested
      return 'BindableOptionsAvailable'
    case ProgramView.ISSUE_BIND_OFFER:
      return 'BindOrderInitiated'
    default:
      return 'SpecsAvailable'
  }
}

const {
  body,
  debouncedBody,
  subject,
  dialog,
  loading,
  email,
  emailBody,
  debouncedEmailBody,
  template,
  wholeTemplate,
  handleEditTemplate,
  handleUpdatePreview,
  resetContent
} = useClearingSubmission()

const attachedDocuments = ref<DocumentDto[]>([])
const provisionDocuments = ref<DocumentDto[]>([])
const attachSov = ref<boolean>(false)
const attachCoverage = ref<boolean>(false)
const attachMarketing = ref<boolean>(false)
const selectedTemplate = ref<string>(getTemplate())
const from = ref<string>(authStore.user?.email ?? '')
const provisionedUsers = await getProvisionedUsers(props.deal!.id!)
const fromList = provisionedUsers.filter((u) => u.orgType == 'Admin' || u.orgType == 'RiskSpecialist').map((u) => u.email)

const filteredDocuments = computed(() => (documents.value ?? []).filter((d: DocumentDto) => !documentNotShareable(d)))

const AVAILABLE_TEMPLATES = computed(() =>
  (templates.value ?? []).filter((t) =>
    ['SpecsAvailable', 'MarketingActivityAvailable', 'BindableOptionsAvailable', 'BindOrderInitiated'].includes(t.templateId!)
  )
)

programStore.$subscribe(async (mutation, state) => {
  if (mutation.storeId === 'program' && mutation.type === 'patch object' && state.isViewPublishedToRetailer) {
    publishButtonColor.value = 'green'
  } else {
    publishButtonColor.value = 'pink'
  }
})

const draft = computed(
  (): ClearingModel =>
    getDraft({
      attachmentIds: attachedDocuments.value.map((e) => e.id ?? '').filter((e) => !!e),
      attachSov: attachSov.value,
      attachCoverage: attachCoverage.value,
      attachMarketing: attachMarketing.value,
      companyId: props.organizationId,
      companyName: org.value?.name ?? '',
      dealId,
      documentIds: provisionDocuments.value.map((e) => e.id ?? '').filter((e) => !!e),
      otherContactIds: [],
      otherEmailAddresses: [],
      primaryContactIds: selectedUsers.value.map(({ id }) => id),
      primaryEmailAddresses: selectedUsers.value.map(({ email }) => email),
      programIds: [programId],
      templateId: selectedTemplate.value
    } as GetDraftType)
)

watch(selectedTemplate, () => {
  subject.value = ''
  email.value = {} as RenderTemplateResponse
})

watch([debouncedBody, subject, selectedUsers, selectedTemplate], async () => {
  if ((Object.values(errors.value) ?? []).every((e) => e.length === 0)) {
    await _handleUpdatePreview()
  }
})

async function _handleEditTemplate() {
  await handleEditTemplate({
    emailService,
    draft: draft.value,
    dealId,
    marketId: props.organizationId,
    programId
  } as HandleEditTemplateType)
}

async function _handleUpdatePreview() {
  await handleUpdatePreview({
    emailService,
    dealId,
    draft: draft.value,
    marketId: props.organizationId,
    programId
  } as HandleUpdatePreviewType)
}

const user_schema = z.array(z.string()).min(1, 'Please select at least 1 recipient')

const errors = computed(() => {
  const error_object = { users: [], list: [] }
  const user_result = user_schema.safeParse(selectedUsers.value.map((c: MappedUserType) => c.email))
  if (!user_result.success) {
    error_object.users = user_result.error.flatten()?.formErrors ?? []
  }
  for (const key of Object.keys(error_object)) {
    if (key !== 'list') {
      error_object.list = [...error_object.list, ...error_object[key]]
    }
  }
  return error_object
})

async function handleSubmit() {
  loading.value = true
  if (!errors.value?.list?.length) {
    try {
      const clearing_object = {
        ...draft.value,
        fromEmailAddress: from.value,
        messageSubject: subject.value ?? email.value.subject
      }
      if (!wholeTemplate.value) {
        clearing_object.customMessage = body.value
      } else {
        clearing_object.customMessage = emailBody.value
      }
      const clearings = [clearing_object]
      const res: CreateClearingResponse = await clearingService.createClearing({ clearings })
      if (res.clearings?.length) {
        await clearingService.sendClearings({ clearings: [res.clearings[0]] })
      }
      notificationStore.publishSuccessMessage('Published to Retailer')
      submitDialog.value = false
      closeDialog()
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    } finally {
      loading.value = false
    }
  }
}

function closeDialog() {
  selectedUsers.value = []
  attachedDocuments.value = []
  provisionDocuments.value = []
  attachSov.value = false
  attachCoverage.value = false
  attachMarketing.value = false
  selectedTemplate.value = getTemplate()
  resetContent()
  dialog.value = false
}

const draftEditorState = computed(() => ({
  selectedOrganization: [org],
  selectedProgram: { id: programId, name: props.programName } as ProgramModel,
  selectedTemplate: AVAILABLE_TEMPLATES.value.find((t) => t.templateId === selectedTemplate.value)
}))
</script>

<template>
  <div>
    <rq-loading-overlay-linear :model-value="loading">Sending Clearing...</rq-loading-overlay-linear>
    <Dialog v-model:open="dialog">
      <TooltipProvider :delay-duration="100">
        <Tooltip>
          <TooltipTrigger as-child>
            <DialogTrigger as-child>
              <rq-btn
                id="programs-navbar-publish-to-retailer-button"
                :class="cn(publishButtonColor === 'pink' ? 'bg-pink-400 hover:bg-pink-500' : 'bg-green-500 hover:bg-green-600')"
                datacy="publish-to-retailer"
                icon="square"
                size="lg"
                variant="ghost"
                vitest="publish-to-retailer"
              >
                <rq-icon color="white" icon="lucide:send" />
              </rq-btn>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>Publish to Retailer</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent
        class="mt-[2rem] flex h-[calc(100vh-5rem)] w-screen max-w-[100vw] flex-col rounded-none md:w-screen"
        @escape-key-down.prevent=""
        @interact-outside.prevent=""
        @pointer-down-outside.prevent=""
      >
        <DialogHeader>
          <DialogTitle>Publish to Retailer</DialogTitle>
          <DialogDescription>Quickly send out a C/S message without leaving the program page.</DialogDescription>
        </DialogHeader>
        <div class="flex grow flex-col overflow-auto px-1.5 text-start">
          <div class="grid grid-cols-3 gap-4 pb-3">
            <rq-listbox-single
              v-model="selectedTemplate"
              datacy="clearing-template"
              hint="THIS IS AUTO-GENERATED. CHANGE AT YOUR OWN RISK"
              :items="AVAILABLE_TEMPLATES"
              label="Message Type"
              :readonly="wholeTemplate"
              text-field="description"
              value-field="templateId"
              wrapper-class="col-span-1"
            />
            <rq-text-field id="program-name" class="col-span-1" label="Program" :model-value="programName" readonly />
            <rq-combobox-multi
              v-model="selectedUsers"
              :items="availableUsers"
              label="Select Recipients"
              return-object
              text-field="formattedName"
              tooltip-content-class="max-w-[300px]"
              truncate-length="64"
              value-field="email"
            />
            <rq-listbox-multi
              v-model="attachedDocuments"
              all-selectable
              :items="filteredDocuments"
              label="Attach Documents"
              text-field="name"
              tooltip-content-class="max-w-[300px]"
              truncate-length="96"
              value-field="id"
              wrapper-class="col-span-1 pt-4 md:col-span-3"
            />
            <rq-listbox-multi
              v-model="provisionDocuments"
              all-selectable
              :items="filteredDocuments"
              label="Provision Documents"
              text-field="name"
              tooltip-content-class="max-w-[300px]"
              truncate-length="96"
              value-field="id"
              wrapper-class="col-span-1 pt-4 md:col-span-3"
            />
            <div class="col-span-1 space-y-1 md:col-span-3">
              <rq-checkbox v-model:checked="attachSov" id="attach-sov" label="Attach arqu SOV"></rq-checkbox>
              <rq-checkbox v-model:checked="attachCoverage" id="attach-coverage" label="Attach arqu Coverage Summary"></rq-checkbox>
              <rq-checkbox v-model:checked="attachMarketing" id="attach-marketing" label="Attach arqu Marketing Log"></rq-checkbox>
            </div>
          </div>
          <div v-if="!wholeTemplate" class="grid grow grid-cols-1 gap-4 lg:grid-cols-2">
            <ClearingSubmissionDraftEditor
              v-model:body="body"
              v-model:from="from"
              v-model:subject="subject"
              class="shadow-none"
              :deal="deal"
              :edit-template-disabled="draftDisabled"
              :errors="errors.users"
              :from-list="fromList"
              :show-actions="true"
              :state="draftEditorState"
              @edit-template="_handleEditTemplate"
            />
            <ClearingSubmissionDraftPreview
              class="shadow-none"
              :draft-disabled="draftDisabled"
              :errors="errors.list"
              :in-clearing-submission="false"
              :template="template"
            />
          </div>
          <div v-else class="grid grow grid-cols-1 gap-4 lg:grid-cols-2">
            <ClearingSubmissionDraftEditor
              v-model:body="emailBody"
              v-model:from="from"
              v-model:subject="subject"
              class="shadow-none"
              :deal="deal"
              :from-list="fromList"
              :show-actions="false"
              :state="draftEditorState"
            />
            <ClearingSubmissionDraftPreview
              class="shadow-none"
              :draft-disabled="draftDisabled"
              :errors="errors.list"
              :in-clearing-submission="false"
              :template="debouncedEmailBody as string"
            />
          </div>
        </div>
        <DialogFooter class="flex flex-col space-y-2 pt-2 sm:flex-row sm:space-x-2 sm:space-y-0 md:justify-between">
          <router-link
            :to="{
              name: 'ClearingAndSubmissionDraft',
              params: { dealId },
              query: { programId, marketId: organizationId, templateId: selectedTemplate }
            }"
          >
            <rq-btn id="go-to-cs" datacy="publish-to-retailer-go-to-cs" variant="primary-outline">Go to C/S</rq-btn>
          </router-link>
          <div class="flex space-x-2">
            <rq-btn id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="closeDialog">Cancel</rq-btn>
            <PublishToRetailerConfirmationDialog
              :attachment-ids="draft.attachmentIds"
              :disabled="errors.list.length > 0"
              :document-ids="draft.documentIds"
              :documents="documents"
              :loading="loading"
              :other-email-addresses="draft.otherEmailAddresses"
              :primary-email-addresses="draft.primaryEmailAddresses"
              @submit="handleSubmit"
            />
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>

<style scoped lang="scss"></style>
