<script setup lang="ts">
import { cn } from '@/lib/utils'
import { useProgramStore, type VisibleSideBarOption } from '@/stores/program'

const programStore = useProgramStore()
const activePanelColor = (panel: VisibleSideBarOption) => (programStore.visibleSideBar === panel ? 'bg-gray-800 hover:bg-gray-400' : '')
const activePanelButtonColor = (panel: VisibleSideBarOption) => (programStore.visibleSideBar === panel ? 'text-white' : 'text-gray-800')

function handlePanelClick(panel?: VisibleSideBarOption): void {
  programStore.visibleSideBar = panel ? panel : 'both'
}

const buttons: { tooltipText: string; panel: VisibleSideBarOption; icon: string; id: string }[] = [
  {
    tooltipText: 'Hide Left Panel',
    panel: 'right',
    icon: 'lucide:panel-left-close',
    id: 'program-navbar-hide-left-panel-button'
  },
  {
    tooltipText: 'Show Both Panels',
    panel: 'both',
    icon: 'lucide:panels-left-right',
    id: 'program-navbar-show-both-panels-button'
  },
  {
    tooltipText: 'Hide Right Panel',
    panel: 'left',
    icon: 'lucide:panel-right-close',
    id: 'program-navbar-hide-right-panel-button'
  }
]
</script>

<template>
  <div class="flex items-center space-x-1 pl-2">
    <rq-tooltip-btn
      v-for="button in buttons"
      :id="button.id"
      :key="button.panel"
      :class="cn(activePanelColor(button.panel), activePanelButtonColor(button.panel))"
      :content="button.tooltipText"
      :datacy="`panel-button-${button.panel}`"
      icon="square"
      size="lg"
      type="button"
      variant="ghost"
      @click="handlePanelClick(button.panel)"
    >
      <rq-icon :class="activePanelButtonColor(button.panel)" :icon="button.icon" />
    </rq-tooltip-btn>
  </div>
</template>
