import 'reflect-metadata'

import { plainToClassFromExist } from 'class-transformer'

import type {
  ClientInfoModel,
  CoverageInfoModel,
  DealConfigModel,
  DealModel,
  InsuredInfoModel,
  PolicyInfoModel,
  ProjectInfoModel,
  PublicUserInfoModel,
  RetailerInfoModel
} from '@/capability/deal/types/deal-model'
import type { DealModelConstructionAttr } from '@/capability/deal/types/deal-model-construction-attr'
import { resourceModelDefaultValueService } from '@/capability/resource/ResourceModelDefaultValueService'
import type {
  DealDtoDealProgressEnum,
  DealDtoPolicyTermEnum,
  DealDtoPracticeEnum,
  DealDtoStatusEnum
} from 'typescript-core-api-client/dist/api'

/**
 * Lightweight Client-side model for a Tower
 */
export class DealModelImpl implements DealModel {
  id?: string
  name?: string
  additionalInformation?: string
  clientAccountId?: string
  policyInfo?: PolicyInfoModel
  clientInfo?: ClientInfoModel
  projectInfo?: ProjectInfoModel
  coverageInfo?: CoverageInfoModel
  retailerInfo?: RetailerInfoModel
  insuredInfo?: InsuredInfoModel
  dealProgress?: DealDtoDealProgressEnum
  description?: string
  displayName?: string
  folderId?: string
  industries?: Array<string>
  labelId?: string
  labelName?: string
  placementName?: string
  policyTerm?: DealDtoPolicyTermEnum
  practice?: DealDtoPracticeEnum
  products?: Array<string>
  status?: DealDtoStatusEnum
  userId?: string
  userOwner?: PublicUserInfoModel
  config?: DealConfigModel
  lastModifiedBy?: string
  lastModifiedByUser?: PublicUserInfoModel
  dateCreatedMs?: number
  lastModifiedMs?: number
  parentDealId?: string

  constructor(attr: DealModelConstructionAttr) {
    if (attr && Object.prototype.hasOwnProperty.call(attr, 'dealDto')) {
      plainToClassFromExist(this, attr.dealDto)
    } else {
      Object.assign(this, {})
    }

    if (Object.prototype.hasOwnProperty.call(attr, 'id')) {
      this.id = attr.id!
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'name')) {
      this.name = attr.name!
    }

    resourceModelDefaultValueService.superimposeDealDefaultsWhereUndefinedInPlace({ deal: this, recursively: true })
  }
}
