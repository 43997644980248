import isEqual from 'lodash.isequal'

import type { IndicationLayerPairModel, IndicationLineItemPairModel, IndicationMarketModel } from '@/capability/indication/model'
import type { LayerModel } from '@/capability/layer/LayerModel'
import type { OrganizationModel } from '@/capability/organization/model'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import type { LayerDto, TowerDto } from 'typescript-core-api-client'

export function getLayerPairComponentSumModel(layer: LayerDto, layerPairs: any[]): IndicationLayerPairModel {
  return {
    layerId: layer.id,
    exposureId: undefined,
    request: {
      participation: layer.layerTarget?.participation,
      premium: layer.layerTarget?.premium,
      rate: layer.layerTarget?.rate
    },
    response: {
      participation: String(layerPairs.map((lp) => +(lp.response?.participation || 0)).reduce((a, b) => a + b, 0)),
      premium: String(layerPairs.map((lp) => +(lp.response?.premium || 0)).reduce((a, b) => a + b, 0)),
      rate: layer.layerTarget?.rate
    }
  }
}

export function getLayerPairsSumMap(
  programModel: ProgramModel,
  layerPairs: IndicationLayerPairModel[]
): Record<string, IndicationLayerPairModel> {
  const results: Record<string, IndicationLayerPairModel> = {}
  programModel.towers?.forEach((tower) => {
    tower?.layers?.forEach((layer) => {
      if (layer?.layers == null || layer.layers.length == 0) {
        const layerPairsByLayerId = layerPairs.filter((lp) => lp.layerId === layer.id)
        results[layer.id!!] = getLayerPairComponentSumModel(layer, layerPairsByLayerId)
      } else {
        layer?.layers?.forEach((segment) => {
          const layerPairsByLayerId = layerPairs.filter((lp) => lp.layerId === segment.id)
          if (layerPairs) {
            results[segment.id!!] = getLayerPairComponentSumModel(segment, layerPairsByLayerId)
          }
        })
      }
    })
  })
  return results
}

/**
 * Return the layer pairs by the given layer id and sort by participation, marketId
 * @param layerPairsModel
 * @param layerId
 */
export function layerPairsByLayerId(layerPairsModel: Array<IndicationLayerPairModel>, layerId: string) {
  return layerPairsModel
    .filter((lp) => lp.layerId === layerId && lp.exposureId == null)
    .sort((a: IndicationLayerPairModel, b: IndicationLayerPairModel) => {
      if (a.response?.participation === b.response?.participation) {
        return a.marketId?.localeCompare(b.marketId!) || 0
      } else {
        return +(a.response?.participation || 0) > +(b.response?.participation || 0) ? -1 : 1
      }
    })
}

export function sortMarketByTotalParticipation(
  markets: IndicationMarketModel[],
  layerPairs: IndicationLayerPairModel[]
): IndicationMarketModel[] {
  return markets.sort((a: IndicationMarketModel, b: IndicationMarketModel) => {
    const layerPairsA = layerPairs.filter((lp) => lp.marketId === a.marketId)
    const layerPairsB = layerPairs.filter((lp) => lp.marketId === b.marketId)
    const layerPairsASum = layerPairsA.reduce((a, b) => a + +(b.response?.participation || 0), 0)
    const layerPairsBSum = layerPairsB.reduce((a, b) => a + +(b.response?.participation || 0), 0)
    if (layerPairsASum === layerPairsBSum) {
      return a.marketId?.localeCompare(b.marketId!) || 0
    } else {
      return layerPairsASum > layerPairsBSum ? -1 : 1
    }
  })
}

export function exposureLayerPairsByLayerId(layerPairsModel: Array<IndicationLayerPairModel>, layerId: string) {
  return layerPairsModel.filter((lp) => lp.layerId === layerId && lp.exposureId != null)
}

export const getCarrierNameById = (carriers: Array<OrganizationModel>, carrierId: string) => carriers.find((c) => c.id === carrierId)?.name

export function getAllLayers(towerModel: TowerDto): LayerModel[] {
  return towerModel?.layers?.map((layer) => getLayers(layer as LayerModel)).flat() || []
}

function getLayers(layerDto: LayerModel): LayerModel[] {
  if (!layerDto.layers || layerDto.layers?.length === 0) {
    return [layerDto]
  } else {
    return layerDto.layers
      .map((layer) => getLayers(layer))
      .flat()
      .concat(layerDto)
  }
}

export const getDynamicClass = (lineItemPair: IndicationLineItemPairModel): string => {
  const valuesEqual =
    !!lineItemPair?.request?.targetValue &&
    !!lineItemPair?.response?.targetValue &&
    isEqual(lineItemPair?.request?.targetValue, lineItemPair?.response?.targetValue)
  const qualifiersEqual =
    !!lineItemPair?.request?.targetQualifier &&
    !!lineItemPair?.response?.targetQualifier &&
    isEqual(lineItemPair?.request?.targetQualifier, lineItemPair?.response?.targetQualifier)
  if (valuesEqual && qualifiersEqual) return 'equal'
  if (qualifiersEqual) return 'outlined inequal'
  return 'inequal'
}
