import type { Ref } from 'vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

type UseCustomTableSortingType<T extends string> = {
  initialSortBy?: T
  initialSortDir?: 'asc' | 'desc'
}

export function useCustomTableSorting<T extends string>(payload: UseCustomTableSortingType<T>) {
  const route = useRoute()

  // Use URL params if they exist, otherwise fall back to provided defaults
  const sortBy = ref<T | ''>((route.query.sortBy as T) || payload.initialSortBy || '') as Ref<T | ''>

  const sortDir = ref<'asc' | 'desc' | ''>((route.query.sortDir as 'asc' | 'desc') || payload.initialSortDir || 'asc')

  function handleHeaderClicked(key: T) {
    if (sortBy.value !== key) {
      sortBy.value = key
      sortDir.value = 'desc'
    } else if (sortDir.value === 'desc') {
      sortDir.value = 'asc'
    } else {
      sortBy.value = ''
      sortDir.value = ''
    }
  }

  function getHeaderIcon(key: T) {
    if (sortBy.value === key) {
      return sortDir.value === 'asc' ? 'lucide:chevron-up' : 'lucide:chevron-down'
    }
    return 'lucide:chevrons-up-down'
  }

  function getIconClass(key: T) {
    let classString = 'transition-all duration-300 group-hover:opacity-100'
    if (!sortBy.value) {
      classString = `${classString} opacity-50`
    } else if (sortBy.value !== key) {
      classString = `${classString} opacity-20`
    }
    return classString
  }

  return {
    sortBy,
    sortDir,
    handleHeaderClicked,
    getHeaderIcon,
    getIconClass
  }
}
