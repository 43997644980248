import { type Ref, ref, type UnwrapRef } from 'vue'
import lodashGet from 'lodash.get'
import lodashSet from 'lodash.set'
import { z } from 'zod'

import type { ConstructErrorType, ErrorType, UseZodPayloadType } from '@/lib/types'

/**
 *
 * This composable ONLY works for creating a schema based off of a reactive state. If you're using a "singleton" setup
 * then this will not work; it still needs to be updated.
 *
 * The way to use this composable is to define the initial state as a general object in the component that is using this
 * composable in a manner similar to
 * const stateObject = {
 *     name: '',
 *     email: '',
 *     keep_open: false
 * }
 * This composable will create a reactive state object based off of this initial state object.
 *
 * and set up the schemaObject as an object of zod validations (just without the wrapping z.object()) like so
 * const schemaObject = {
 *     name: z.string().nonempty(),
 *     email: z.string().email(),
 * }
 * and then pass both into the composable. The `objectRefine` is an optional parameter that can be used to add a
 * refinement to the schema. This is useful for when you want to add a refinement to the schema that is based off of
 * the state. For example, if you want to make sure that the email is unique and you have a list of emails available
 * to check against, you can do something like
 * function objectRefine_function(obj) {
 *     return !emails.includes(obj.email)
 * }
 * and set the message/path to be like
 * const objectRefine_details = {
 *    message: 'Email must be unique',
 *    path: ['email']
 * }
 * then you pass both of those into the composable as
 * const objectRefine = [{
 *   _function: objectRefine_function,
 *   _details: objectRefine_details
 * }]
 * Note: You can have as many refinements as you want, just pass them in an array in the order in which you want them
 * called
 *
 * You also will need to create a state that is a `ref`:
 * const state = ref<typeof stateObject>(Object.assign({}, stateObject)
 *
 * You would then call the composable like so
 * const { errors, resetErrors, resetState, validate } = useZod<typeof stateObject>({ state, stateObject, schemaObject, objectRefine })
 * Here the errors would be the reactive errors
 * object that you defined above, the clear_errors would be a function that clears the errors, the resetState would be
 * a function that resets the state to the original state, and validate would be a function that validates the
 * reactive state against the schema and returns a boolean.
 *
 * Your template can then look something like
 * <template>
 *   <form @submit.prevent="handleSubmit">
 *     <input v-model="state.name" :errors="errors.name" />
 *     <input v-model="state.email" :errors="errors.email" />
 *     <rq-alert v-if="errors.field_errors.length" type="error">
 *       {{ errors.field_errors[0] }}
 *     </rq-alert>
 *     <button>Validate</button>
 *   </form>
 * </template>
 *
 * and in the script you would call
 * function handleSubmit() {
 *   clear_errors()
 *   if (validate()) {
 *     // do something
 *     resetState()
 *   }
 * }
 */

export interface UseZodReturn<T extends Record<string, unknown>> {
  errors: Ref<UnwrapRef<ErrorType<T>>>
  resetErrors: () => void
  resetState: () => void
  validate: () => boolean
  actions?: { [K in keyof T]: { onBlur: () => void; onInput: () => void } }
}

export function useZod<T extends Record<string, unknown>>(payload: UseZodPayloadType<T>): UseZodReturn<T> {
  const { state, stateObject, schemaObject, objectRefine } = payload

  const errors = ref<ErrorType<T>>(clear_errors())
  function resetState() {
    for (const key in stateObject) {
      state.value[key] = stateObject[key]
    }
    for (const key in dirty.value) {
      // @ts-ignore -- Vue UnwrapRef typing issues with indexing
      dirty.value[key] = false
    }
  }

  function resetErrors() {
    errors.value = clear_errors() as UnwrapRef<U>
  }

  function clear_errors(): ErrorType<T> {
    const obj = Object.keys(stateObject).reduce((acc, key) => ({ ...acc, [key]: [] }), {} as ConstructErrorType<T>)
    obj.field_errors = []
    return obj
  }

  function validate(): boolean {
    let schema = z.object(schemaObject)
    if (objectRefine?.length) {
      for (const refine of objectRefine) {
        // @ts-ignore
        schema = schema.refine(refine._function, refine._details)
      }
    }
    resetErrors()
    const res = schema.safeParse(state.value)
    if (!res.success) {
      for (const issue of res.error.issues) {
        const path = issue.path.join('.') || ['field_errors']
        const value = lodashGet(errors.value, path)
        lodashSet(errors.value as Object, path, [...value, issue.message])
      }
    }
    return res.success
  }

  const dirtyObj = {} as Record<keyof T, boolean>
  Object.keys(stateObject).forEach((key) => {
    // @ts-ignore -- No need to worry too much about `dirtyObj` type handling since it's just a placeholder
    dirtyObj[key] = !!stateObject[key]
  })
  const dirty = ref<Record<keyof T, boolean>>(dirtyObj)

  function validateField(field: keyof T): void {
    const dirtyField = field as keyof typeof dirty.value

    if (!dirty.value[dirtyField]) return

    // First validate just the field with its basic schema
    let fieldSchema = z.object({ [field]: schemaObject[field] })
    let fieldValue = { [field]: state.value[field] }

    // Clear previous errors for this field
    lodashSet(errors.value as Object, field, [])

    // Do basic field validation first
    const fieldRes = fieldSchema.safeParse(fieldValue)

    if (!fieldRes.success) {
      const fieldErrors = fieldRes.error.issues.map((issue) => issue.message)
      lodashSet(errors.value as Object, field, fieldErrors)
      return // Return early if basic validation fails
    }

    // If field passes basic validation, check any refinements that target this field
    if (objectRefine?.length) {
      const relevantRefinements = objectRefine.filter((refine) => refine._details.path?.includes(field as string))

      for (const refine of relevantRefinements) {
        const isValid = refine._function(state.value)
        if (!isValid) {
          const currentErrors = lodashGet(errors.value, field, [])
          lodashSet(errors.value as Object, field, [...currentErrors, refine._details.message])
        }
      }
    }
  }

  // Create actions object that works with v-bind
  const actions = Object.keys(stateObject).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        onBlur: () => {
          // @ts-ignore -- Vue UnwrapRef typing issues with indexing
          dirty.value[key] = true
          validateField(key)
        },
        onInput: () => {
          if (state.value[key] === schemaObject[key]) {
            dirty.value[key] = false
          } else {
            validateField(key as keyof T)
          }
        }
      }
    }),
    {} as { [K in keyof T]: { onBlur: () => void; onInput: () => void } }
  )

  return { errors, resetErrors, resetState, validate, actions }
}
