export const apiLocationOrigin = import.meta.env.VITE_API_LOCATION_ORIGIN || 'https://api.development.arqu.co'

export const corpDirectoryLabel = import.meta.env.VITE_CORP_DIRECTORY_LABEL

// export const inAppVisualDebugging = import.meta.env.VITE_IN_APP_VISUAL_DEBUGGING_FLAG ?? true

export const v1AppLocationOrigin = import.meta.env.VITE_V1_APP_LOCATION_ORIGIN

// export const useOnlyV2Links = import.meta.env.VITE_USE_ONLY_V2_LINKS

// export const noTopNav = import.meta.env.VITE_NO_TOP_NAVBAR

// export const noDealNav = import.meta.env.VITE_NO_DEAL_NAVBAR

// export const webAppBranch = import.meta.env.VITE_GIT_COMMIT_HASH

// export const webAppCommitSha = import.meta.env.VITE_GIT_BRANCH

export default {
  apiLocationOrigin,
  corpDirectoryLabel,
  // inAppVisualDebugging,
  v1AppLocationOrigin
  // useOnlyV2Links,
  // noTopNav,
  // noDealNav,
  // webAppBranch,
  // webAppCommitSha
}
