<script setup lang="ts">
import { cn, toggleElement } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import type { LineItemNavigationItemType } from '@/capability/program/types'
import { navigationItems } from '@/capability/program/utils'

const programStore = useProgramStore()

const activeColor = (button: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(button.location) ? 'bg-orange-400 hover:bg-orange-500' : 'bg-transparent hover:bg-orange-100'
const activeButtonColor = (button: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(button.location) ? 'text-white' : 'text-orange-400'

const handleClick = (button: LineItemNavigationItemType): void => {
  programStore.visibleSection = toggleElement(programStore.visibleSection, button.location)
  // window.scrollTo({ top: 99999, behavior: 'smooth' })
}
</script>

<template>
  <div class="flex items-center space-x-1 pl-2">
    <template v-for="button in navigationItems" :key="button.name">
      <rq-tooltip-btn
        :id="`program-line-items-navigation-${button.location}`"
        :class="cn(activeColor(button))"
        :content="button.name"
        :datacy="`line-items-navigation-${button.location}`"
        :delay-duration="100"
        icon="square"
        size="lg"
        variant="ghost"
        @click="handleClick(button)"
      >
        <rq-icon :class="activeButtonColor(button)" :icon="button.icon" />
      </rq-tooltip-btn>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
