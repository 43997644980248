<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { z } from 'zod'

import { useZod } from '@/composables/zod'
import type { DataTableHeader } from '@/lib/types/data-table'
import { useNotificationStore } from '@/stores/notification'

import { lineItemService } from '@/capability/line-item/LineItemService'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { CreateTowerLineItemTemplateRequest, LineItemTargetDto, LineItemTemplateDto } from 'typescript-core-api-client'

import ApplyLineItemTemplate from './ApplyLineItemTemplate.vue'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import DeleteLineItemTemplate from '@/component/line-item-template/DeleteLineItemTemplate.vue'

const notificationStore = useNotificationStore()

const props = defineProps({
  lineItemType: {
    type: String,
    required: true
  },
  tower: {
    type: Object as PropType<TowerModel>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:tower', payload: TowerModel): void
}>()

const route = useRoute()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(true)

const stateObject = {
  name: '',
  description: ''
}

const state = ref<typeof stateObject>({ name: '', description: '' })

const schemaObject = {
  name: z.string().min(1, 'Name is required')
}

const { errors, resetState, resetErrors, validate } = useZod<typeof stateObject>({
  state,
  stateObject,
  schemaObject
})

const programId = route.params.programId as string
const createTowerTemplate = ref<CreateTowerLineItemTemplateRequest | null>(null)

const lineItemTemplates = ref<LineItemTemplateDto[]>([])

const headers: DataTableHeader[] = [
  {
    value: 'name',
    text: 'Name',
    width: '220px',
    sortable: true,
    align: 'start'
  },
  {
    value: 'description',
    text: 'Description',
    width: '220px',
    sortable: true,
    align: 'start'
  },
  {
    value: 'lineItemTargetCount',
    text: '# of items',
    width: '220px',
    sortable: true,
    align: 'start'
  },
  {
    text: 'Action',
    align: 'start',
    value: 'action',
    width: '40px'
  }
]

watch(dialog, async (value) => {
  if (value) {
    await getLineItemTemplates()
    loading.value = false
  } else {
    resetErrors()
    resetState()
    loading.value = true
  }
})

async function handleSubmit() {
  try {
    loading.value = true
    resetErrors()
    const valid = validate()
    if (valid) {
      createTowerTemplate.value = {
        towerId: props.tower!.id as string,
        programId,
        lineItemTemplateType: props.lineItemType as string,
        name: state.value.name as string,
        description: state.value.description as string
      }
      await lineItemService.createTowerLineItemTemplate(createTowerTemplate.value as CreateTowerLineItemTemplateRequest)
      notificationStore.publishSuccessMessage(props.lineItemType + ' Template created')
      closeDialog()
    }
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  } finally {
    loading.value = false
  }
}

async function getLineItemTemplates() {
  const res: LineItemTemplateDto[] = await lineItemService.listTowerLineItemTemplates()
  lineItemTemplates.value = res.map(
    (e: { id: any; dateCreatedMs: any; title: any; subtitle: any; lineItemTargets: LineItemTargetDto[]; lineItemTargetCount: number }) => ({
      id: e.id,
      dateCreated: e.dateCreatedMs,
      name: e.title,
      description: e.subtitle,
      lineItemTargets: e.lineItemTargets,
      lineItemTargetCount: e.lineItemTargets?.length ?? 0
    })
  ) as LineItemTemplateDto[]
}

function closeDialog() {
  dialog.value = false
}

async function handleApply(payload: TowerModel) {
  emit('update:tower', payload)
  closeDialog()
}

async function handleDelete() {
  await getLineItemTemplates()
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <rq-btn class="mx-1 my-2 flex items-center px-2 py-4" datacy="apply-t-c-template-trigger" variant="gray" v-bind="$attrs">
        <div class="mr-1 rounded-lg bg-[#8d86b2] px-1.5">
          <rq-icon color="white" icon="mdi:plus-circle-multiple" />
        </div>
        <span class="inline text-center">Apply T&#38;C Template</span>
        <rq-tooltip :text="`${lineItemType} Templates`" />
      </rq-btn>
    </DialogTrigger>
    <DialogContent
      class="min-h-[25vh] min-w-[95vw] sm:min-w-[85vw] md:min-w-[75vw]"
      label="line-item-templates"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <DialogHeader>
        <DialogTitle>{{ lineItemType }} Templates</DialogTitle>
        <DialogDescription>Create, apply, or delete a T&#38;C template</DialogDescription>
      </DialogHeader>
      <div class="overflow-auto p-1">
        <p class="text-sm font-semibold">
          Add new template based upon
          <span class="inline-flex text-base font-normal italic">{{ tower.name }}</span>
          Terms &#38; Conditions
        </p>
        <form class="pb-2" @submit.prevent="handleSubmit">
          <div class="grid grid-cols-3 gap-3 py-2">
            <rq-text-field
              v-model="state.name"
              id="name"
              datacy="new-line-item-template-name"
              :errors="errors.name"
              label="Name"
              :variant="errors.name.length > 0 ? 'error' : 'default'"
              wrapper-class="col-span-1"
            />
            <rq-text-field
              v-model="state.description"
              id="description"
              :errors="errors.description"
              label="Description"
              placeholder="Optional"
              :variant="errors.description.length > 0 ? 'error' : 'default'"
              wrapper-class="col-span-2"
            />
          </div>
          <div class="flex justify-end">
            <rq-btn
              class="flex w-1/4 items-center space-x-2"
              datacy="create-new-line-item-template-submit"
              :loading="loading"
              type="submit"
              variant="primary-outline"
            >
              <rq-icon icon="lucide:plus-circle" />
              <span>Create</span>
            </rq-btn>
          </div>
        </form>
        <div v-if="loading" class="flex justify-center">
          <rq-progress-circular class="mx-auto" indeterminate size="100" />
        </div>
        <rq-data-table
          v-else
          class="max-h-[50vh]"
          datacy="line-item-templates-table"
          :headers="headers"
          :items="lineItemTemplates"
          :pagination="true"
          :pagination-above="true"
          :sticky-header="true"
        >
          <template #action="{ item }">
            <div class="flex items-center">
              <ApplyLineItemTemplate
                :line-item-template-id="item.id"
                :line-item-templates="lineItemTemplates"
                :name="item.name"
                :tower="tower"
                @submit="handleApply"
              />
              <DeleteLineItemTemplate
                :line-item-template-id="item.id"
                :line-item-templates="lineItemTemplates"
                :name="item.name"
                :tower="tower"
                @submit="handleDelete"
              />
            </div>
          </template>
        </rq-data-table>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">
.icon-background-color {
  background-color: #8d86b2;
}
</style>
