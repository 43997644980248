import { apiConfiguration } from '@/capability/system/Config'
import type {
  AIChatRequestAgentTypeEnum,
  BatchShareDocumentRequest,
  DeleteBatchDocumentsResponse,
  DocumentChatHistoryResponse,
  DocumentChatResponse,
  DocumentDto,
  DocumentExtractionRequest,
  DocumentPrivilegesDto,
  DocumentTypeDto,
  GetProvisionedDocumentsResponse,
  PreviewDocumentResponse,
  ShareDocumentRequest,
  ShareDocumentsRequest,
  UpdateDocumentRequest
} from 'typescript-core-api-client'
import { DocumentApi } from 'typescript-core-api-client'
import type { UpdateMultipleDocumentsRequest } from 'typescript-core-api-client/dist/api'
import type { DeleteMultipleDocumentsRequest } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

type GetDocumentPayloadType = {
  docId: string
}

type DownloadDocumentPayloadType = {
  docId: string
}

type PreviewDocumentPayloadType = {
  docId: string
}

type UpdateDocumentContentPayloadType = {
  docId: string
  file: File
}

type UpdateDocumentPayloadType = {
  docId: string
  request: UpdateDocumentRequest
}

type ExtractFromDocumentPayloadType = {
  docId: string
  request: DocumentExtractionRequest
}

type GetProvisionedDocumentsPayloadType = {
  dealId: string
  organizationId: string
}

type ShareDocumentPayloadType = {
  docId: string
  request: ShareDocumentRequest
}

type ShareBatchPayloadType = {
  request: BatchShareDocumentRequest
}

type ShareDocumentsPayloadType = {
  request: ShareDocumentsRequest
}

type GetUserDocumentPrivilegesByIdPayloadType = {
  docId: string
}

type GetUserDocumentPrivilegesByIdsPayloadType = {
  docIds: string[]
}

type GetUserGroupDocumentPrivilegesByIdPayloadType = {
  docId: string
}

type DeleteDocumentPayloadType = {
  docId: string
}

type DownloadMultiplePayloadType = {
  docIds: string[]
}

export type ChatPayloadType = {
  dealId?: string
  docId?: string
  agentType: AIChatRequestAgentTypeEnum
  question: string
  sessionId: string
  regenerate?: boolean
}

type GetChatHistoryPayloadType = {
  dealId?: string
  documentId?: string
  agentType: AIChatRequestAgentTypeEnum
  sessionId: string
}

type ClearChatHistoryPayloadType = {
  dealId?: string
  documentId?: string
  agentType: AIChatRequestAgentTypeEnum
  sessionId: string
}

type DownloadSovPayloadType = {
  dealId?: string
}

export interface DocumentService {
  getDocumentById: (payload: GetDocumentPayloadType) => Promise<DocumentDto>
  downloadDocument: (payload: DownloadDocumentPayloadType) => Promise<any>
  documentDocumentAsBase64: (payload: DownloadDocumentPayloadType) => Promise<string>
  previewDocument: (payload: PreviewDocumentPayloadType) => Promise<PreviewDocumentResponse>
  getDocumentTypes: () => Promise<DocumentTypeDto[]>
  updateContent: (payload: UpdateDocumentContentPayloadType) => Promise<DocumentDto>
  updateDocument: (payload: UpdateDocumentPayloadType) => Promise<DocumentDto>
  updateMultipleDocuments: (payload: UpdateMultipleDocumentsRequest) => Promise<DocumentDto[]>
  extractFromDocument: (payload: ExtractFromDocumentPayloadType) => Promise<void>
  getProvisionedDocuments: (payload: GetProvisionedDocumentsPayloadType) => Promise<GetProvisionedDocumentsResponse>
  shareDocument: (payload: ShareDocumentPayloadType) => Promise<void>
  shareBatch: (payload: ShareBatchPayloadType) => Promise<void>
  shareDocuments: (payload: ShareDocumentsPayloadType) => Promise<void>
  getUserDocumentPrivilegesById: (payload: GetUserDocumentPrivilegesByIdPayloadType) => Promise<DocumentPrivilegesDto[]>
  getUserDocumentsPrivilegesByIds: (payload: GetUserDocumentPrivilegesByIdsPayloadType) => Promise<Map<string, DocumentPrivilegesDto[]>>
  getUserGroupDocumentPrivilegesById: (payload: GetUserGroupDocumentPrivilegesByIdPayloadType) => Promise<DocumentPrivilegesDto[]>
  deleteDocument: (payload: DeleteDocumentPayloadType) => Promise<void>
  deleteBatch: (payload: DeleteMultipleDocumentsRequest) => Promise<DeleteBatchDocumentsResponse>
  downloadMultiple: (payload: DownloadMultiplePayloadType) => Promise<Blob>
  downloadMultipleAsBase64: (payload: DownloadMultiplePayloadType) => Promise<string>
  chat: (payload: ChatPayloadType) => Promise<DocumentChatResponse>
  getChatHistory: (payload: GetChatHistoryPayloadType) => Promise<DocumentChatHistoryResponse>
  clearChatHistory: (payload: ClearChatHistoryPayloadType) => Promise<void>
  downloadSov: (payload: DownloadSovPayloadType) => Promise<Blob>
}

export const documentService = {
  getDocumentById: async function (payload: GetDocumentPayloadType): Promise<DocumentDto> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.get(docId)
    return response.data.document!
  },
  downloadDocument: async function (payload: DownloadDocumentPayloadType): Promise<any> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    return await documentApi.download(docId, { responseType: 'blob' })
  },
  documentDocumentAsBase64: async function (payload: DownloadDocumentPayloadType): Promise<string> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)
    const response = await documentApi.downloadAsBase64(docId)
    return response.data.content ?? ''
  },
  previewDocument: async function (payload: PreviewDocumentPayloadType): Promise<PreviewDocumentResponse> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.preview(docId)
    return response.data
  },
  getDocumentTypes: async function (): Promise<DocumentTypeDto[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.getDocumentTypes()
    return response.data.documentTypes ?? []
  },
  updateContent: async function (payload: UpdateDocumentContentPayloadType): Promise<DocumentDto> {
    const { docId, file } = payload
    const config: CoreApiConfiguration = await apiConfiguration()

    const fileData = new FormData()
    fileData.append('files', file)

    const response = await fetch(`${config.basePath}/v1/documents/${docId}/content`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
        'X-Arqu-Session-Id': sessionStorage.getItem('rq-session') || '',
        Accept: 'application/json, text/plain, */*'
      },
      body: fileData
    })

    if (!response.ok) {
      throw new Error('Failed to update document content')
    }

    const data: { document: DocumentDto } = await response.json()
    return data.document!
  },
  updateDocument: async function (payload: UpdateDocumentPayloadType): Promise<DocumentDto> {
    const { docId, request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.update(docId, request)
    return response.data.document!
  },
  updateMultipleDocuments: async function (payload: UpdateMultipleDocumentsRequest): Promise<DocumentDto[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.updateMultiple(payload)
    return response.data.map((d) => d.document!)
  },
  extractFromDocument: async function (payload: ExtractFromDocumentPayloadType): Promise<void> {
    const { docId, request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    await documentApi.extract(docId, request)
  },
  getProvisionedDocuments: async function (payload: GetProvisionedDocumentsPayloadType): Promise<GetProvisionedDocumentsResponse> {
    const { dealId, organizationId } = payload
    const config = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.getProvisionedDocuments(dealId, organizationId)
    return response.data
  },
  shareDocument: async function (payload: ShareDocumentPayloadType): Promise<void> {
    const { docId, request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    await documentApi.share(docId, request)
  },
  shareBatch: async function (payload: ShareBatchPayloadType): Promise<void> {
    const { request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    await documentApi.shareBatch(request)
  },
  shareDocuments: async function (payload: ShareDocumentsPayloadType): Promise<void> {
    const { request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    await documentApi.shareDocuments(request)
  },
  getUserDocumentPrivilegesById: async function (payload: GetUserDocumentPrivilegesByIdPayloadType): Promise<DocumentPrivilegesDto[]> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.privilegesByDocument(docId)
    return response.data.documentPrivileges ?? []
  },
  getUserDocumentsPrivilegesByIds: async function (
    payload: GetUserDocumentPrivilegesByIdsPayloadType
  ): Promise<Map<string, DocumentPrivilegesDto[]>> {
    const { docIds } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.privilegesByDocuments(docIds)
    const privileges = response.data.documentPrivileges
    if (privileges) {
      return new Map(Object.entries(privileges))
    } else {
      return new Map()
    }
  },
  getUserGroupDocumentPrivilegesById: async function (
    payload: GetUserGroupDocumentPrivilegesByIdPayloadType
  ): Promise<DocumentPrivilegesDto[]> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.privilegesUserGroupByDocument(docId)
    return response.data.documentPrivileges ?? []
  },
  deleteDocument: async function (payload: DeleteDocumentPayloadType): Promise<void> {
    const { docId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    await documentApi._delete(docId)
  },
  deleteBatch: async function (payload: DeleteMultipleDocumentsRequest): Promise<DeleteBatchDocumentsResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    return (await documentApi.deleteBatch(payload)).data
  },
  downloadMultiple: async function (payload: DownloadMultiplePayloadType): Promise<Blob> {
    const { docIds } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.downloadMultipleDocuments(docIds, { responseType: 'blob' })
    return response.data as any
  },
  downloadMultipleAsBase64: async function (payload: DownloadMultiplePayloadType): Promise<string> {
    const { docIds } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const documentApi = new DocumentApi(config)

    const response = await documentApi.downloadMultipleDocumentsAsBase64(docIds)
    return response.data.content ?? ''
  },
  chat: async function (payload: ChatPayloadType): Promise<DocumentChatResponse> {
    const { dealId, docId, agentType, question, sessionId, regenerate } = payload
    const config = await apiConfiguration()
    const documentApi = new DocumentApi(config)
    const response = await documentApi.chat({
      agentType,
      dealId: dealId || 'undefined',
      docId,
      question,
      sessionId,
      regenerate
    })
    return response.data
  },
  getChatHistory: async function (payload: GetChatHistoryPayloadType): Promise<DocumentChatHistoryResponse> {
    const { dealId, documentId, agentType, sessionId } = payload
    const config = await apiConfiguration()
    const documentApi = new DocumentApi(config)
    const response = await documentApi.chatHistory(agentType, dealId || 'undefined', sessionId, documentId)
    return response.data
  },
  clearChatHistory: async function (payload: ClearChatHistoryPayloadType): Promise<void> {
    const { dealId, documentId, agentType, sessionId } = payload
    const config = await apiConfiguration()
    const documentApi = new DocumentApi(config)
    await documentApi.clearChatHistory(agentType, dealId || 'undefined', sessionId, documentId)
  },
  downloadSov: async function (payload: DownloadSovPayloadType): Promise<Blob> {
    const { dealId } = payload
    const config = await apiConfiguration()
    const documentApi = new DocumentApi(config)
    const response = await documentApi.downloadStatementOfValueDocument(dealId || 'undefined', { responseType: 'blob' })
    return response.data as any
  }
} as DocumentService
