<script lang="ts">
import type { TooltipProviderProps } from 'radix-vue'

export interface Props extends TooltipProviderProps {
  content?: string
  contentProps?: string
}

export type Emits = {
  (e: 'click', event: MouseEvent): void
}

export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { cn } from '@/lib/utils'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = withDefaults(defineProps<Props>(), {
  content: '',
  contentProps: ''
})

const emit = defineEmits<Emits>()

const tooltipProviderProps = computed(() => {
  const { content, contentProps, ...rest } = props
  return rest
})

const handleClick = (event: MouseEvent) => {
  emit('click', event)
}
</script>

<template>
  <TooltipProvider v-bind="tooltipProviderProps">
    <Tooltip>
      <TooltipTrigger as-child>
        <rq-btn v-bind="$attrs" @click="handleClick">
          <slot>Click</slot>
        </rq-btn>
      </TooltipTrigger>
      <TooltipContent :class="cn(contentProps)">
        <slot name="content">{{ content }}</slot>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<style scoped lang="scss"></style>
