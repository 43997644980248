<script lang="ts">
import type { ProgramModel } from '@/capability/program/ProgramModel'

export type Props = {
  program: ProgramModel
}
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { z } from 'zod'

import { useNotificationStore } from '@/stores/notification'

import { EXPOSURES } from '@/capability/exposure/constants'
import { exposureService } from '@/capability/exposure/service'
import type { ExposureModel } from '@/capability/exposure/types'
import type { ExposureType } from '@/capability/exposure/types'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ProgramAddExposureTypeSelector from '@/component/program/program-navbar/add-exposure/ProgramAddExposureTypeSelector.vue'

const props = defineProps<Props>()
const exposures = defineModel<ExposureModel[]>('exposures', { default: [] })

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const notificationStore = useNotificationStore()

const selectedExposureTypes = ref<ExposureType[]>(
  EXPOSURES.filter((type: any) => (exposures.value ?? []).map(({ name }) => name).includes(type.name))
)
const selectedExposureTypeNames = computed(() => selectedExposureTypes.value.map((e) => e.name))

const schema = z.array(z.string()).refine((v) => {
  if ((exposures.value ?? []).length === 0) {
    return v.length > 0
  }
  return true
}, 'Please select at least one exposure type')

const errors = ref<string[]>([])

async function handleSubmit() {
  try {
    loading.value = true
    errors.value = []
    schema.parse(selectedExposureTypeNames.value)

    // Create promises for new exposures
    const newExposurePromises = EXPOSURES.filter((type: any) => selectedExposureTypeNames.value.includes(type.name))
      .filter((type) => !exposures.value.map((exposure) => exposure.name).includes(type.name))
      .map((e) =>
        exposureService.createExposure({
          exposure: {
            label: e.title,
            name: e.name,
            programId: props.program!.id!,
            towers: props.program?.towers ?? []
          }
        })
      )

    // Create promises for exposures to delete
    const deleteExposures = exposures.value.filter(({ name }) => !selectedExposureTypeNames.value.includes(name))

    const deletePromises = deleteExposures
      .filter(({ id }) => id)
      .map(({ id }) => exposureService.deleteExposure({ exposureId: id as string }))

    // Handle new exposures first
    return Promise.allSettled(newExposurePromises)
      .then((newExposureResults) => {
        // Extract successful new exposures
        const newExposures = newExposureResults
          .filter((result): result is PromiseFulfilledResult<ExposureModel> => result.status === 'fulfilled')
          .map((result) => result.value)

        // If we have deletions to perform, handle those next
        if (deletePromises.length) {
          return Promise.allSettled(deletePromises).then(() => newExposures) // Pass along the new exposures
        }
        return newExposures
      })
      .then((newExposures) => {
        // Update exposures value with new state
        exposures.value = [...exposures.value.filter(({ id }) => !deleteExposures.map(({ id: _id }) => _id).includes(id)), ...newExposures]

        closeDialog()
        notificationStore.publishSuccessMessage('Exposures updated successfully')
      })
      .catch((e) => {
        notificationStore.publishOneOrMoreErrUnhandled(e as Error)
      })
      .finally(() => {
        loading.value = false
      })
  } catch (e) {
    if (e instanceof z.ZodError) {
      errors.value = e.flatten()?.formErrors ?? []
    } else {
      notificationStore.publishOneOrMoreErrUnhandled(e as unknown as Error)
    }
  } finally {
    loading.value = false
  }
}

const closeDialog = () => {
  dialog.value = false
  errors.value = []
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <TooltipProvider :delay-duration="100">
      <Tooltip>
        <TooltipTrigger as-child>
          <DialogTrigger as-child>
            <rq-btn
              id="programs-navbar-add-exposure-button"
              class="bg-pink-400 hover:bg-pink-500"
              datacy="add-exposure-btn"
              :disabled="!(program!.towers ?? []).length"
              icon="square"
              size="lg"
              variant="ghost"
            >
              <rq-icon color="white" icon="lucide:clipboard-list" />
            </rq-btn>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Add/Remove Exposures</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Add/Remove Exposures</DialogTitle>
        <DialogDescription>You can add or remove exposures to/from the program</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="space-y-2">
          <ProgramAddExposureTypeSelector v-model="selectedExposureTypes" :errors="errors" :items="EXPOSURES" />
          <div class="max-h-72 min-h-24 overflow-y-auto">
            <ul class="list-disc space-y-1">
              <li
                v-for="exposure in selectedExposureTypes"
                :key="exposure.name"
                class="selected-exposure-item flex rounded px-2 py-1 hover:bg-slate-100"
              >
                <span class="flex-1">{{ exposure.title }}</span>
                <rq-btn
                  class="rm-btn"
                  icon="rounded"
                  size="xs"
                  variant="ghost-destructive"
                  @click="() => (selectedExposureTypes = selectedExposureTypes.filter((ex) => ex.name !== exposure.name))"
                >
                  <rq-icon icon="lucide:x" size="small" />
                </rq-btn>
              </li>
            </ul>
          </div>
          <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
            <div class="flex space-x-2">
              <rq-btn
                id="cancel-btn"
                :disabled="loading"
                :style="{ border: '1px solid rgb(229 231 235) !important' }"
                type="button"
                variant="outline"
                @click="dialog = false"
              >
                Cancel
              </rq-btn>
              <rq-btn
                id="success-btn"
                class="space-x-2"
                datacy="dialogCardActionsSubmitButton"
                :disabled="loading"
                type="submit"
                variant="primary"
              >
                <rq-icon v-if="loading" class="animate-spin" icon="lucide:loader" />
                <span>Add/Remove Exposures</span>
              </rq-btn>
            </div>
          </DialogFooter>
        </div>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped>
.selected-exposure-item .rm-btn {
  opacity: 0;
  transition: opacity 0.3s;
}

.selected-exposure-item:hover .rm-btn {
  opacity: 1;
}
</style>
