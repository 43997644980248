import { getLogger } from '@/composables/util/log/logger'
import { apiLocationOrigin } from '@/lib/config'
import { rqDatadog } from '@/lib/utils/datadog'
import { setDatadogRumUser } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

import {
  deleteAccessTokenCookie,
  deleteLegacyAccessTokenCookie,
  setAccessTokenCookie,
  setUserIdCookie
} from '@/capability/authentication/authenticationUtil'
import { SigninResult } from '@/capability/authentication/SigninResult'
import { systemEventCaptureService } from '@/capability/event/SystemEventCaptureService'
import { apiConfiguration } from '@/capability/system/Config'
import { type CreateUserResponse, type SendVerificationCodeResponse, UserApi } from 'typescript-core-api-client'
import type { UserOrOrganizationExistsResponse, WhoAmIResponse } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

/**
 * SigninService responsible for user authentication and other user related operations.
 */

export type PerformSigninPayloadType = {
  username: string
  password: string
  rememberMe: boolean
}
export type SendMfaCodePayloadType = {
  mfaCode: string
  mfaToken: string
  email: string
  rememberMe: boolean
}
export type VerifyEmailPayloadType = {
  email: string
  token: string
}
export type ResetPasswordPayloadType = {
  email: string
}
export type ChangePasswordPayloadType = {
  currentPassword: string
  newPassword: string
  token: string
}
export type CreateUserPayloadType = {
  firstName: string
  lastName: string
  email: string
  organizationId: string
  password: string
  sharedDealId: string
  referrerUrl: string
}
export type ExistsPayloadType = {
  email: string
}
export type SendVerificationEmailPayloadType = {
  email: string
}
export type SendVerificationCodePayloadType = {
  email: string
  source: string
  referrerUrl?: string
}
export type LoginAsPayloadType = {
  userId: string
}

export interface SigninService {
  /**
   * Asynchronously Sign-in a user with the specified username and password
   * @param username
   * @param password
   */
  performSignin: (payload: PerformSigninPayloadType) => Promise<SigninResult>
  refreshToken: () => Promise<void>
  logout: () => void
  whoami: () => Promise<WhoAmIResponse>
  sendMfaCode: (payload: SendMfaCodePayloadType) => Promise<void>
  verifyEmail: (payload: VerifyEmailPayloadType) => Promise<void>
  resetPassword: (payload: ResetPasswordPayloadType) => Promise<void>
  changePassword: (payload: ChangePasswordPayloadType) => Promise<void>
  createUser: (payload: CreateUserPayloadType) => Promise<CreateUserResponse>
  /**
   * I assume that this confirms if the given email is registered
   * @param email
   */
  exists: (payload: ExistsPayloadType) => Promise<UserOrOrganizationExistsResponse>
  sendVerificationEmail: (payload: SendVerificationEmailPayloadType) => Promise<void>
  sendVerificationCode: (payload: SendVerificationCodePayloadType) => Promise<SendVerificationCodeResponse>
  loginAs: (payload: LoginAsPayloadType) => Promise<void>
}

const oauthUsername = 'lucidrisq-web'
const oauthPassword = 'password'

export const signinService = {
  performSignin: async function (payload: PerformSigninPayloadType): Promise<SigninResult> {
    const { username, password, rememberMe } = payload
    if (!username || !password) {
      throw new Error('Username and password are required')
    }

    const authStore = useAuthStore()
    const notificationStore = useNotificationStore()
    const logger = getLogger('auth.SigninServiceImpl')

    try {
      const loginFormData = new FormData()
      loginFormData.append('username', username)
      loginFormData.append('password', password)
      loginFormData.append('grant_type', 'password')

      const response = await fetch(`${apiLocationOrigin}/oauth/token`, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ' + btoa(`${oauthUsername}:${oauthPassword}`)
        },
        body: loginFormData
      })

      const data: { access_token: string; token_type: string; refresh_token: string; expires_in: number } = await response.json()

      notificationStore.publishSuccessMessage('Sign-in Success')

      authStore.accessToken = data.access_token
      authStore.refreshToken = data.refresh_token

      const whoamiResponse = await this.whoami()
      authStore.$patch({
        user: whoamiResponse.user!,
        privileges: whoamiResponse.privileges || [],
        features: whoamiResponse.features || []
      })

      if (rememberMe) {
        deleteLegacyAccessTokenCookie()
        deleteAccessTokenCookie()
        setAccessTokenCookie(authStore.accessToken)
      }
      setUserIdCookie(authStore.user?.id!)
      return new SigninResult(data.access_token)
    } catch (err) {
      // 403 is a MFA required http status code
      if (err.response && err.response.status === 403) {
        throw err
      } else {
        notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
        throw err
      }
    }
  },
  refreshToken: async function (): Promise<void> {
    const authStore = useAuthStore()
    try {
      if (!authStore.refreshToken) {
        return
      }
      const formData = new FormData()
      formData.append('grant_type', 'refresh_token')
      formData.append('refresh_token', authStore.refreshToken)

      const config: CoreApiConfiguration = await apiConfiguration()
      delete config.accessToken
      config.baseOptions.headers['Authorization'] = null

      const response = await fetch(`${apiLocationOrigin}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ' + btoa(`${oauthUsername}:${oauthPassword}`)
        },
        body: formData
      })

      const data: { access_token: string; token_type: string; refresh_token: string; expires_in: number } = await response.json()
      authStore.$patch({ accessToken: data.access_token })
      if (data.refresh_token) {
        authStore.$patch({ refreshToken: data.refresh_token })
      }
      setAccessTokenCookie(authStore.accessToken)
    } catch (e) {
      if (e.response.status >= 400) {
        deleteAccessTokenCookie()
        deleteLegacyAccessTokenCookie()
        authStore.$patch({ accessToken: '', refreshToken: '' })
        systemEventCaptureService.fireAndForgetProgrammaticEvent({
          pageId: 'signin-service',
          code: `refresh-token-failure`,
          resourceType: 'user-session',
          resourceCrudl: 'delete',
          reason: e.response.status
        })
      }
      throw e
    }
  },
  logout: function (): void {
    const authStore = useAuthStore()
    authStore.$patch({
      accessToken: '',
      refreshToken: '',
      user: undefined
    })
    deleteLegacyAccessTokenCookie()
    deleteAccessTokenCookie()
    systemEventCaptureService.fireAndForgetProgrammaticEvent({
      pageId: 'signin-service',
      code: 'logout-success',
      resourceType: 'user-session',
      resourceCrudl: 'delete'
    })
  },
  whoami: async function (): Promise<WhoAmIResponse> {
    const logger = getLogger('auth.SigninServiceImpl')
    try {
      const config: CoreApiConfiguration = await apiConfiguration()
      const userApi = new UserApi(config)
      const response = await userApi.whoami()
      return (response as any).data
    } catch (e) {
      if (e.response?.status == 401 && e.response?.data?.error == 'invalid_token') {
        logger.info('refreshing token', { context: { method: 'whoami' } })
        await this.refreshToken()
        const config: CoreApiConfiguration = await apiConfiguration()
        const userApi = new UserApi(config)
        const response = await userApi.whoami()
        return (response as any).data
      } else {
        throw e
      }
    }
  },
  sendMfaCode: async function (payload: SendMfaCodePayloadType): Promise<void> {
    const { mfaCode, mfaToken, rememberMe, email } = payload
    const response = await fetch(`${apiLocationOrigin}/oauth2/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(`${oauthUsername}:${oauthPassword}`)
      },
      body: new URLSearchParams({
        grant_type: 'mfa',
        mfa_code: mfaCode.trim(),
        mfa_token: mfaToken,
        username: email
      }).toString()
    })

    const data: { access_token: string; token_type: string; expires_in: number } = await response.json()
    const authStore = useAuthStore()
    authStore.$patch({ accessToken: data.access_token })

    if (rememberMe) {
      deleteLegacyAccessTokenCookie()
      deleteAccessTokenCookie()
      setAccessTokenCookie(authStore.accessToken)
    }

    const whoamiResponse = await this.whoami()
    authStore.$patch({
      user: whoamiResponse.user!,
      privileges: whoamiResponse.privileges || [],
      features: whoamiResponse.features || [],
      refreshToken: whoamiResponse.refreshToken || ''
    })

    if (authStore.user) {
      rqDatadog.updateUser(authStore.user!, authStore.features)
      setDatadogRumUser(authStore.user!)
      setUserIdCookie(authStore.user.id!)
    }
  },
  verifyEmail: async function (payload: VerifyEmailPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const { email, token } = payload
    await userApi.verifyEmail({ email, token })
  },
  resetPassword: async function ({ email }: ResetPasswordPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    await userApi.resetPassword({ email })
  },
  changePassword: async function (payload: ChangePasswordPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const { currentPassword, newPassword, token } = payload
    const response = await userApi.changePassword({ currentPassword, newPassword, token })
    const authStore = useAuthStore()
    if (response.data.token) {
      authStore.accessToken = response.data.token
      const whoamiResponse = await this.whoami()
      authStore.$patch({
        user: whoamiResponse.user!,
        privileges: whoamiResponse.privileges || [],
        features: whoamiResponse.features || []
      })
      setAccessTokenCookie(authStore.accessToken)
    }
  },
  createUser: async function (payload: CreateUserPayloadType): Promise<CreateUserResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const { firstName, lastName, email, password, organizationId, sharedDealId, referrerUrl } = payload
    const response = await userApi.createUser({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      orgId: organizationId,
      sharedDealId: sharedDealId,
      referrerUrl: referrerUrl
    })
    return response.data || {}
  },
  exists: async function ({ email }: ExistsPayloadType): Promise<UserOrOrganizationExistsResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const response = await userApi.exists({ email })
    return response.data
  },
  sendVerificationEmail: async function ({ email }: SendVerificationEmailPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)
    await userApi.sendVerificationEmail({ email })
  },
  sendVerificationCode: async function (payload: SendVerificationCodePayloadType): Promise<SendVerificationCodeResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const { email, source, referrerUrl } = payload
    const response = await userApi.sendMfaEmail({ email, source, referrerUrl })
    return response.data
  },
  loginAs: async function ({ userId }: LoginAsPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const userApi = new UserApi(config)

    const userResponse = await userApi.getUserById(userId)
    const privilegeResponse = await userApi.listPrivileges(userId)

    const authStore = useAuthStore()
    authStore.$patch({ viewedAsUser: userResponse.data, viewedAsPrivileges: privilegeResponse.data.privileges })
  }
} as SigninService
